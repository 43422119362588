import Image from 'next/image';
import { Col, Row, Container } from "react-bootstrap";
import Layout from "@/components/layout";
import Link from 'next/link';
import React from 'react';


const ExperienceEnergyHealing = () => {

    return (
        <>
            <section className="learnreiki-section">
        <Container>
          <Row>
            <Col md={11} className="m-auto">
              <div className="middle-box">
                <Row>
                  <Col md={7} className="align-self-center">
                    <div className="inner-content">
                      <h3>Do You Want to Experience Energy Healing?</h3>
                      <p>With Zenkify witness the Best Experience of Life because you are worth it. Choose Energy Practioners across the globe.


                      </p>
                      <Link href={'/healers'} className="theme-btn" target='blank'>Explore Our Healer Profiles<i className="ri-arrow-right-line"></i></Link>
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="yoga-image">
                      <Image height={403} width={316} src="/images/ExperienceEnergyHealing.svg" alt="Experience Energy Healing" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
        </>
    )
}

export default ExperienceEnergyHealing;

ExperienceEnergyHealing.getLayout = function getLayout(page) {
    return (
        <Layout>{page}</Layout>
    )
}