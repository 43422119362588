import { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import dynamic from "next/dynamic";
import { Container, Col, Row } from "react-bootstrap";
import handLeftSvg from "../../../public/images/icon/left-hand.svg";
import handRightSvg from "../../../public/images/icon/right-hand.svg";
import styles from "@/styles/HealerCourseCarousel.module.css"

const OwlCarousel = dynamic(() => import("react-owl-carousel"), {
  ssr: false,
});

export default function HealerCoursesCaraousel({ props }) {
  const [coursesList, setCoursesList] = useState(props.coursesList) || [];

  return (
    <>
      {coursesList?.data?.length ? (
        <section className={styles["ourcourses-section"]}>
          <Container>
            <Row className={styles["courses-head-img"]}>
              <Col md={2}>
                <Image src={handLeftSvg} alt="Reiki Healing Course" />
              </Col>
              <Col md={8}>
                <div className="section-title">
                  <h3 className="title">courses offered</h3>
                  <p>
                    Our verified healers are providing various courses in all
                    different modalities which anyone can learn and get
                    certified to help connect with your inner self and practice
                    self care.
                  </p>
                </div>
              </Col>
              <Col md={2}>
                <Image src={handRightSvg} alt="Relki Healing Course" />
              </Col>
            </Row>
            <Col md={12}> 
              {coursesList?.data?.length ? (
                <OwlCarousel
                  className={styles["courses-slider"]+" "+ "owl-theme"}
                  loop={false}
                  margin={15}
                  nav={false}
                  dots={true}
                  responsive={{
                    0: {
                      items: 1,
                    },
                    600: {
                      items: 2,
                    },
                    1000: {
                      items: 4,
                    },
                  }}
                >
                  {coursesList?.data?.map((item, index) => {
                    return (
                      <Col className="item mb-3" key={index}>
                        <div className={styles["courses-card"]}>
                          <div className={styles["courses-content"]}>
                            <h3>{(item?.class?.name?.length > 25 ? item?.class?.name?.slice(0,85) : item?.class?.name) ?? "Not Specified"}</h3>
                            <h4>
                                {(item?.class?.short_description?.length > 25 ? item?.class?.short_description?.slice(0,100) : item?.class?.short_description) ?? "Not Specified"}
                            </h4>
                            <ul>
                              <li>
                                <i className="ri-time-line"></i>{" "}
                                <span>
                                  {item?.class?.duration
                                    ? item?.class?.duration + " Days"
                                    : "Not Specified"}
                                </span>
                              </li>
                              <li>
                                <i className="ri-user-3-line"></i>{" "}
                                <span>
                                  {item?.booking_type?.name ? item?.booking_type?.name=="Both" ? "In-Person & Online" : item?.booking_type?.name : "Not Specified"}
                                </span>
                              </li>
                            </ul>
                            <Link
                            href={`/courses/${item.slug}/detail`}
                              className="theme-btn"
                            >
                              Book Class <i className="ri-arrow-right-line"></i>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </OwlCarousel>
              ) : (
                ""
              )}
            </Col>
            <Col md={12} className="mt-3 text-center">
              <Link href={"/courses"} className="theme-btn">
                Explore More Courses <i className="ri-arrow-right-line"></i>
              </Link>
            </Col>
          </Container>
        </section>
      ) : (
        ""
      )}
    </>
  );
}
