import { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import dynamic from "next/dynamic";
import styles from '@/styles/BlogSlider.module.css';

const OwlCarousel = dynamic(() => import("react-owl-carousel"), {
  ssr: false,
});

const BlogSlider = ({ props }) => {
  const [sliderInfo, setSliderInfo] = useState(props?.blogs ?? []);
  const [src, setSrc] = useState(null);

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const validateImgUrl = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  return (
    <>
      {sliderInfo.length ?
        <OwlCarousel
          className="blog-slider owl-theme"
          loop={false}
          margin={10}
          nav={false}
          dots={true}
          responsive={{
            0: {
              items: 1,
            },
            600: {
              items: 3,
            },
            1000: {
              items: 4,
            },
          }}
        >
          {
            sliderInfo.map((item) => {
              return (
                <>
                  <div className={styles["item"]} key={item.id}>
                    <div className={styles["card-blog"]}>
                      <div className={styles["blog-image"]}>
                        <Image
                          height={400}
                          width={298}
                          src={
                            validateImgUrl(item.og_image)
                              ? item.og_image
                              : "/images/blog-1.jpg"
                          }
                          alt="blog"
                          onError={() => setSrc("/images/blog-1.jpg")}
                        />
                      </div>
                      <div className={styles["blog-content"]}>
                        <div className={styles["blog-date"]}>
                          {formatDate(item.created_at)}
                        </div>
                        <h4>{item?.title ? (item?.title?.length > 25 ? `${item?.title?.slice(0, 60)}.....` : item?.title) : "Not Specified"}</h4>
                        <Link href={`/blogs/${item?.slug}`} className={styles["theme-btn"]} aria-label="Read More">Read More</Link>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          }
        </OwlCarousel> : ""}
    </>
  );
};

export default BlogSlider;
